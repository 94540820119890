<template>
  <div class="register-form">
    <div class="top-section">
      <div class="logo">
        <img src="~@/assets/images/logo/big_logo.png" alt="" />
      </div>
      <div class="hint-section">
        <div class="hint-main">
          Free Register for <span class="eggsbook">ESM</span> <span class="effect"> Market</span>
        </div>
        <div class="hint-sub">Get your free ESM Market account now..</div>
      </div>
    </div>
    <div class="form-section mt-4 mb-3">
      <b-form @submit.prevent="onRegister">
        <div class="form-group">
          <label for="email">User Email</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text>
                <i class="fas fa-envelope"></i>
              </b-input-group-text>
            </template>
            <b-form-input
              type="email"
              required
              id="email"
              v-model.trim="register.email"
              placeholder="Enter Your Email"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="password">User Password</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text @click="showpass1 = !showpass1" class="cursor-pointer">
                <i class="fad fa-key"></i>
              </b-input-group-text>
            </template>
            <b-form-input
              :type="!showpass1 ? 'password' : 'text'"
              required
              id="password"
              v-model.trim="register.password"
              placeholder="Enter Your Password"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="password">Re-User Password</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text @click="showpass2 = !showpass2" class="cursor-pointer">
                <i class="fad fa-key"></i>
              </b-input-group-text>
            </template>
            <b-form-input
              :type="!showpass2 ? 'password' : 'text'"
              required
              id="password"
              v-model.trim="repass"
              placeholder="Enter Your Password"
            ></b-form-input>
          </b-input-group>
          <p
            class="text-small text-danger mb-0"
            style="font-size: 10px"
            v-if="repass && repass !== register.password"
          >
            Re-User Password not like password
          </p>
        </div>
        <div class="form-group">
          <label for="select-type">User Type Account</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text>
                <i class="fad fa-users-cog"></i>
              </b-input-group-text>
            </template>
            <b-form-select
              v-model.trim="register.type_account"
              required
              id="select-type"
              :options="typeAccount"
              value-field="id"
              text-field="name"
              readonly
              disabled
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="country-user">User Country</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text>
                <i class="fad fa-key"></i>
              </b-input-group-text>
            </template>
            <country-select
              v-model="register.country"
              :countryName="true"
              :country="country"
              topCountry="US"
              fetch-country="true"
              required
            />
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="sponsor">User Sponsor (Option)</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text>
                <i class="fad fa-key"></i>
              </b-input-group-text>
            </template>
            <b-form-input
              type="text"
              id="sponsor"
              v-model.trim="register.sponsor"
              placeholder="Enter Your Sponsor"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <div class="forgot">
            <i class="fad fa-lock-alt"></i>
            <span>
              <router-link :to="{ name: 'Forgot' }">Forgot your password ?</router-link>
            </span>
          </div>
        </div>
        <div class="form-group">
          <b-button
            variant="none"
            type="submit"
            class="btn-auth d-flex align-items-center justify-content-center"
            :disabled="!repass || repass !== register.password"
          >
            Register<i class="fas fa-sign-in-alt ml-1"></i>
          </b-button>
        </div>
      </b-form>
    </div>
    <div class="other-section">
      <div class="m-3 text-center redirect-section">
        <h5 class="">Already have an account ?</h5>
        <p class="font-13">
          Login <span class="eggsbook">ESM</span> <span class="effect"> Market</span> Now
        </p>
        <router-link :to="{ name: 'Login' }"> Login ?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      register: {
        email: '',
        password: '',
        type_account: 2,
        country: '',
        sponsor: '',
        token: '',
      },
      repass: '',
      country: '',
      region: '',
      showpass1: false,
      showpass2: false,
    };
  },
  computed: {
    ...mapGetters({
      typeAccount: 'core/typeAccount',
    }),
  },
  created() {
    this.$store.dispatch('core/req_getTypeAccount');
  },
  mounted() {
    if (this.$route.query.email) {
      this.register.email = this.$route.query.email;
    }
    if (this.$route.query.sponsor) {
      this.register.sponsor = this.$route.query.sponsor;
    }
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
    async onRegister() {
      const token = await this.genarateCaptChaV3('register');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.register.token = token;
      this.$store.dispatch('auth/req_postRegister', this.register);
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  min-width: 274px;
  max-width: calc(100% - 50px);
  span {
    &.effect {
      background: #ff5c00;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
    }
    &.eggsbook {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
      color: #5d5d5d;
    }
  }
  .top-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 20px auto;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      flex: 0 0 100px;
      min-width: 100px;
      img {
        height: auto;
        width: 75px;
        margin-bottom: 5px;
      }
    }
    .hint-section {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: #212529;
      .hint-main {
        font-weight: 900;
        font-size: clamp(14px, 1.5vw, 18px);
        line-height: 120%;
        margin-bottom: 5px;
      }
      .hint-sub {
        font-size: clamp(14px, 1vw, 16px);
        color: #98a6ad;
        line-height: 115%;
        font-weight: 600;
      }
      span {
        &.effect {
          background: #ff5c00;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: clamp(14px, 1.5vw, 18px);
          text-transform: uppercase;
          font-weight: 900;
          line-height: 120%;
        }
        &.eggsbook {
          font-size: clamp(14px, 1.5vw, 18px);
          text-transform: uppercase;
          font-weight: 900;
          color: #5d5d5d;
          line-height: 120%;
        }
      }
    }
  }
  .form-section {
    max-width: 100%;
    margin: 0 auto;
    label {
      font-weight: 600;
      color: #6c757d;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .input-group-text {
      background: transparent;
      padding: 0;
      border: 1px solid #e9ecef;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    select,
    input {
      border: 1px solid #e9ecef;
      height: 40px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      padding: 0.375rem 0.75rem;
      max-width: calc(100% - 45px);
      border-radius: 5px;
      &:focus-visible,
      &:focus {
        box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        outline: none;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
    .form-group {
      .forgot {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        i,
        svg {
          margin-right: 5px;
          color: #98a6ad;
          font-size: clamp(14px, 1.5vw, 16px);
        }
        span a {
          color: #98a6ad;
          font-weight: 600;
          font-size: clamp(14px, 1.5vw, 16px);
        }
      }
    }
  }
  button {
    margin-top: 20px;
    &.btn-auth {
      border: 1px solid #ccc;
      box-shadow: 0 2px 6px 0 #ccc;
      letter-spacing: 0.05rem;
      position: relative;
      background: #ff5c00;
      color: #fff !important;
      min-width: 130px;
      width: 100%;
      height: 45px;
      overflow: hidden;
      transition: 0.3s ease-in-out;
      border-radius: 0.3rem;
      z-index: 1;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .other-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h6 {
      color: #5d5b6f;
      font-size: clamp(15px, 1vw, 16px);
      font-weight: 600;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        display: inline-block;
        margin: 0 14px 4px 0;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
      &:after {
        display: inline-block;
        margin: 0 0 4px 14px;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
    }
    button {
      margin-top: 20px;
      border-radius: 50%;
      border: 0;
      background: linear-gradient(45deg, #7ac14e73, #0f9c4f70);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      color: #fff;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        background: #ff5c00;
      }
    }
    .redirect-section {
      font-weight: 600;
      h5 {
        font-size: clamp(14px, 1.5vw, 16px);
        font-weight: 600;
        color: #ff5c00;
      }
      a {
        margin: 20px auto;
        border-radius: 5px;
        border: 0;
        background: #ff5c00;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 40px;
        text-decoration: none;
        color: #fff;
        &:hover,
        &:focus,
        &:focus-visible {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>
